<template>
  <div class="header-link-website-language">
    <SvgIconsLanguage24 class="icon header-link-website-language__icon" />
    <label class="label header-link-website-language__label">
      {{ languageLabel }}</label
    >
  </div>
</template>

<script>
import SvgIconsLanguage24 from "@/components/Svg/Icons/SvgIconsLanguage24.vue";

export default {
  components: { SvgIconsLanguage24 },

  setup() {
    const languageLabel = "PL";

    return {
      languageLabel,
    };
  },
};
</script>

<style scoped>
.header-link-website-language {
  display: flex;
  align-items: center;
  column-gap: 9px;
}
.header-link-website-language__icon {
  min-width: 24px;
}
</style>
