<template>
  <ButtonLink
    :to="{ name: ROUTE_LOGIN_NAME }"
    :label="'Zaloguj się'"
    :size="size"
    :isDisabled="isDisabled"
  />
</template>

<script>
import { ROUTE_LOGIN_NAME } from "@/router/constants";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";

export default {
  components: { ButtonLink },

  props: {
    size: {
      type: String,
      default: "medium",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return { ROUTE_LOGIN_NAME };
  },
};
</script>
