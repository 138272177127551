<template>
  <div class="container-right header__container-right">
    <HeaderLinkWebsiteLanguage />
    <ButtonLinkLogin />
    <ButtonCtaSignup :size="'x-small'" />
  </div>
</template>

<script>
import HeaderLinkWebsiteLanguage from "@/components/Header/HeaderLinkWebsiteLanguage.vue";
import ButtonCtaSignup from "@/components/UI/ButtonCtaSignup.vue";
import ButtonLinkLogin from "@/components/UI/ButtonLinkLogin.vue";

export default {
  components: {
    HeaderLinkWebsiteLanguage,
    ButtonCtaSignup,
    ButtonLinkLogin,
  },
};
</script>

<style scoped>
.header__container-right {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 40px;

  position: relative;
}
</style>
